//import { createTheme } from "@mui/material/styles";

// const theme = createTheme({
//   typography: {
//     fontFamily: ["Droid Serif", "Montserrat", "sans-serif"].join(","),
//   },
//   // You can also set other theme settings such as palette colors, breakpoints, etc.
// });

// src/theme.js
import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  typography: {
    fontFamily: ["Droid Serif", "Montserrat", "sans-serif"].join(","),
  },
  palette: {
    mode: "light",
    text: {
      primary: "#000",
    },
  },
});

const darkTheme = createTheme({
  typography: {
    fontFamily: ["Droid Serif", "Montserrat", "sans-serif"].join(","),
  },
  palette: {
    mode: "dark",
    text: {
      primary: "#fff",
    },
  },
});

// const lightTheme = createTheme({
//   typography: {
//     fontFamily: ["Droid Serif", "Montserrat", "sans-serif"].join(","),
//   },
//   palette: {
//     mode: "light",
//     text: {
//       primary: "#000", // black text for light background
//       secondary: "rgba(0, 0, 0, 0.7)", // grey text for less emphasis
//     },
//   },
// });

// const darkTheme = createTheme({
//   typography: {
//     fontFamily: ["Droid Serif", "Montserrat", "sans-serif"].join(","),
//   },
//   palette: {
//     mode: "dark",
//     text: {
//       primary: "#fff", // white text for dark background
//       secondary: "rgba(255, 255, 255, 0.7)", // slightly dim white for less emphasis
//     },
//   },
// });

export { lightTheme, darkTheme };
