import React, { useState } from "react";
import { fetchData } from "../services/apiService";
import PostCard from "../components/common/PostCard";
import { Pagination, Box, TextField, Button } from "@mui/material";

const Search = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState(""); // State to hold the input field text
  const postsPerPage = 10; // Number of posts per page

  // Calculate total number of pages
  const pageCount = Math.ceil(posts.length / postsPerPage);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleInputChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!search.trim()) return; // Prevent searching with an empty query
    setLoading(true);
    setError(null);
    try {
      // Update the API endpoint to include the submitted search query
      const data = await fetchData(`posts?per_page=50&_embed&search=${search}`);
      setPosts(data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setError(error);
    }
    setLoading(false);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading posts!</div>;

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "20px",
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Search inside my travel journey..."
          value={search}
          onChange={handleInputChange}
          fullWidth
          style={{ marginBottom: "10px" }} // Adding margin for spacing between the text field and the button
        />
        <Button type="submit" className="button-custom">
          Search Posts
        </Button>
      </form>
      {currentPosts.map((post) => (
        <PostCard key={post.id} post={post} />
      ))}
      <Box display="flex" justifyContent="center" my={2} mb={8}>
        <Pagination
          count={pageCount}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          size="large"
          sx={{
            button: { fontSize: "1.25rem" },
            ".MuiPaginationItem-root.Mui-selected": {
              backgroundColor: "#dd9933",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#dd9933",
              },
            },
          }}
        />
      </Box>
    </div>
  );
};

export default Search;
