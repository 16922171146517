import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import SearchIcon from "@mui/icons-material/Search";

import NavigationConfig from "../../data/FooterBar.json";

const iconMapping = {
  HomeIcon: <HomeIcon />,
  PersonIcon: <PersonIcon />,
  HelpOutlineIcon: <HelpOutlineIcon />,
  OndemandVideoIcon: <OndemandVideoIcon />,
  SearchIcon: <SearchIcon />,
};

const FooterBar = ({ toggleDrawer }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const theme = useTheme();

  const StyledButtonBar = styled(BottomNavigation)({
    position: "fixed",
    bottom: 0,
    width: "100%",
    zIndex: 9999,
    height: "80px", // Adjust based on theme or device specifics
    paddingBottom: theme.spacing(2), // Use theme spacing for consistent design
    [theme.breakpoints.down("sm")]: {
      height: "70px", // Smaller height on smaller devices
    },
  });

  return (
    <StyledButtonBar
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
    >
      {NavigationConfig.map((item, index) => (
        <BottomNavigationAction
          key={index}
          label={item.label}
          icon={
            <span style={{ fontSize: "1.5em" }}>{iconMapping[item.icon]}</span>
          } // Larger icon
          onClick={() => {
            toggleDrawer(false)();
            navigate(item.path);
          }}
          sx={{
            paddingBottom: "0", // Adjust padding to align the icon properly
            "& .MuiBottomNavigationAction-label": {
              fontSize: "0.75rem", // Larger label
            },
          }}
        />
      ))}
    </StyledButtonBar>
  );
};

export default FooterBar;
