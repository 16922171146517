import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchData } from "../services/apiService";
import {
  AppBar,
  Toolbar,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Post = () => {
  const [post, setPost] = useState(null);
  const { postId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const loadPost = async () => {
      try {
        const postData = await fetchData(`posts/${postId}`);
        setPost(postData);
      } catch (error) {
        console.error("Failed to fetch post:", error);
      }
    };

    loadPost();
  }, [postId]);

  const navigateToRoot = () => {
    navigate("/");
  };

  if (!post) return <div>Loading...</div>;

  const authorName = post._embedded?.author?.[0]?.name || "Unknown Author";

  return (
    <div>
      <AppBar position="static" className="appbar-custom">
        <Toolbar className="appbar-toolbar-custom" onClick={navigateToRoot}>
          <IconButton edge="start" color="inherit" aria-label="back">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Back
          </Typography>
        </Toolbar>
      </AppBar>

      <Card sx={{ margin: 0 }} className="card-custom">
        <CardHeader
          className="card-header-custom"
          title={
            <Typography
              variant="h5"
              component="div"
              dangerouslySetInnerHTML={{ __html: post.title.rendered }}
            />
          }
          subheader={
            <>
              <span className="author">Carsten Molewijk</span>&nbsp;
              <span className="date">
                {new Date(post.date).toLocaleDateString()}
              </span>
            </>
          }
        />
        <CardContent className="card-content-custom">
          <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
        </CardContent>
      </Card>
    </div>
  );
};

export default Post;
