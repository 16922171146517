import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Brightness4Icon from "@mui/icons-material/Brightness4"; // Dark mode icon
import Brightness7Icon from "@mui/icons-material/Brightness7"; // Light mode icon
import { useTheme } from "@mui/material/styles"; // Import if you are using ThemeProvider
import { useThemeContext } from "../../utils/ThemeContext";

const TopBar = ({ onMenuClick }) => {
  const { toggleTheme, mode } = useThemeContext(); // Use toggleTheme from context

  const title = process.env.REACT_APP_TITLE || "Default Title";

  return (
    <AppBar className="appbar-custom" position="fixed" elevation={0}>
      <Toolbar className="toolbar-custom">
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        {/* <IconButton
          edge="end"
          color="inherit"
          aria-label="toggle theme"
          onClick={toggleTheme}
          sx={{ mr: 2 }}
        >
          {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton> */}
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={onMenuClick}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
