import React from "react";

const SplashScreen = () => {
  return (
    <div className="splash-screen">
      <img
        src="https://bycarsten.nl/wp-content/uploads/2024/04/loader.gif"
        alt="Loading..."
        className="loader"
      />
    </div>
  );
};

export default SplashScreen;
