import React from "react";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const PostCard = ({ post }) => {
  const navigate = useNavigate();

  const handleReadMore = () => {
    navigate(`/post/${post.id}`);
  };

  const authorName = post._embedded?.author?.[0]?.name || "Unknown Author";

  return (
    <Card key={post.id} sx={{ margin: 0 }} className="card-custom">
      <CardHeader
        className="card-header-custom"
        avatar={
          <Avatar
            src={post._embedded?.["wp:featuredmedia"]?.[0]?.source_url}
            alt="Post Image"
            sx={{ width: 72, height: 72 }}
          />
        }
        title={
          <Typography
            variant="h6"
            component="div"
            dangerouslySetInnerHTML={{ __html: post.title.rendered }}
          />
        }
        subheader={
          <>
            <span className="author">{authorName}</span>&nbsp;
            <span className="date">
              {new Date(post.date).toLocaleDateString()}
            </span>
          </>
        }
        onClick={handleReadMore}
      />
      <CardContent className="card-content-custom">
        <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
      </CardContent>
      <CardActions>
        <Button className="button-custom" onClick={handleReadMore}>
          Read the full story
        </Button>
      </CardActions>
    </Card>
  );
};

export default PostCard;
