import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "./utils/ThemeContext";
import { Alert, Snackbar } from "@mui/material";

import useDocumentTitle from "./hooks/useDocumentTitle";
import TopBar from "./components/common/TopBar";
import FooterBar from "./components/common/FooterBar";
import SideBar from "./components/common/SideBar";
import SplashScreen from "./components/common/SplashScreen";
import Posts from "./views/Posts";
import Post from "./views/Post";
import Page from "./views/Page";
import Search from "./views/Search";
import "./App.css";

function App() {
  const title = process.env.REACT_APP_HTML_TITLE || "Default App Title";
  useDocumentTitle(title);

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [updateAlert, setUpdateAlert] = useState(false); // State to control the update alert

  useEffect(() => {
    const handleServiceWorkerMessages = (event) => {
      switch (event.data.type) {
        case "NEW_VERSION":
          if (window.confirm("New content is available. Refresh now?")) {
            window.location.reload();
          } else {
            setUpdateAlert(true);
            setTimeout(() => {
              window.location.reload();
            }, 20000); // Automatically reload after 20 seconds
          }
          break;
        case "PUSH_RECEIVED":
          setMessage(event.data.message);
          break;
        default:
          console.log(
            "Received unknown message type from service worker:",
            event.data.type
          );
      }
    };

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener(
        "message",
        handleServiceWorkerMessages
      );
    }

    return () => {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.removeEventListener(
          "message",
          handleServiceWorkerMessages
        );
      }
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const toggleDrawer = (open) => () => {
    setIsOpen(open);
  };

  return (
    <ThemeProvider>
      <BrowserRouter>
        {loading && <SplashScreen />}
        {!loading && (
          <div className="App">
            <TopBar onMenuClick={toggleDrawer(true)} />
            <SideBar isOpen={isOpen} toggleDrawer={toggleDrawer} />
            <FooterBar toggleDrawer={toggleDrawer} />
            <div className="content">
              {message && (
                <Alert severity="info" className="App-message">
                  Message: {message}
                </Alert>
              )}
              {updateAlert && (
                <Snackbar
                  open={updateAlert}
                  autoHideDuration={20000}
                  onClose={() => setUpdateAlert(false)}
                >
                  <Alert severity="info">
                    A new update will be applied shortly.
                  </Alert>
                </Snackbar>
              )}
              <Routes>
                <Route path="/" element={<Posts />} />
                <Route path="/post/:postId" element={<Post />} />
                <Route path="/page/:pageId" element={<Page />} />
                <Route path="/search" element={<Search />} />
              </Routes>
            </div>
          </div>
        )}
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
