import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchData } from "../services/apiService";
import { Card, CardHeader, CardContent, Typography } from "@mui/material";

const Page = () => {
  const [page, setPage] = useState(null);
  const { pageId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const loadPage = async () => {
      try {
        const postData = await fetchData(`pages/${pageId}`);
        setPage(postData);
      } catch (error) {
        console.error("Failed to fetch page:", error);
      }
    };

    loadPage();
  }, [pageId]);

  const navigateToRoot = () => {
    navigate("/");
  };

  if (!page) return <div>Loading...</div>;

  return (
    <div>
      <Card sx={{ margin: 0 }} className="card-custom">
        <CardHeader
          className="card-header-custom"
          title={
            <Typography
              variant="h5"
              component="div"
              dangerouslySetInnerHTML={{ __html: page.title.rendered }}
            />
          }
        />
        <CardContent className="card-content-custom">
          <div dangerouslySetInnerHTML={{ __html: page.content.rendered }} />
        </CardContent>
      </Card>
    </div>
  );
};

export default Page;
